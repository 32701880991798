<template>
  <div>
    <b-row class="profile-icons-svg">
      <b-col lg="6">
        <div class="iq-card ">
          <div class="iq-card-body pb-1">
            <div class="d-flex align-items-center justify-content-between">
              <p class="font-size-26 p-0 m-0">{{ $t('userProfile.orders') }}</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="25.135" height="25.135" viewBox="0 0 30.135 30.135">
                <path id="doctor-bag" d="M29.786,9.7H26.438V8.022A5.022,5.022,0,0,0,21.416,3h-6.7A5.022,5.022,0,0,0,9.7,8.022V9.7H6.348A3.358,3.358,0,0,0,3,13.045V29.787a3.358,3.358,0,0,0,3.348,3.348H29.786a3.358,3.358,0,0,0,3.348-3.348V13.045A3.358,3.358,0,0,0,29.786,9.7ZM13.045,8.022a1.674,1.674,0,0,1,1.674-1.674h6.7A1.674,1.674,0,0,1,23.09,8.022V9.7H13.045ZM23.09,23.09H19.741v3.348H16.393V23.09H13.045V19.742h3.348V16.393h3.348v3.348H23.09Z" transform="translate(-3 -3)" fill="#646464"  />
              </svg>
            </div>
            <p class="font-size-30 text-end m-0 pt-4">{{ pagination.totalItems }}</p>
          </div>
        </div>
      </b-col>
    </b-row>
<!--    {{orders}}-->
    <user-pages-title>
      <template v-slot:header-icon>
        <svg id="delivery-truck_1_" data-name="delivery-truck (1)" xmlns="http://www.w3.org/2000/svg" width="30.466" height="20.496" viewBox="0 0 33.466 24.496">
          <g id="Group_145" data-name="Group 145" transform="translate(0 0)">
            <g id="Group_144" data-name="Group 144">
              <path id="Path_133" data-name="Path 133" d="M0,234.777a.611.611,0,0,0,.584.665h.932a4.131,4.131,0,0,1,3.706-2.633,4.131,4.131,0,0,1,3.706,2.633h12.11v-5.377H0Z" transform="translate(0 -215.695)" fill="#646464" />
              <path id="Path_134" data-name="Path 134" d="M33.218,277.32a2.991,2.991,0,1,0,2.746,2.981A2.871,2.871,0,0,0,33.218,277.32Z" transform="translate(-27.997 -258.785)" fill="#646464" />
              <path id="Path_135" data-name="Path 135" d="M285.771,192.312l-2.616-1.248H275v8.816h1.583a3.925,3.925,0,0,1,7.412,0h1.549a.612.612,0,0,0,.584-.665v-6.262A.7.7,0,0,0,285.771,192.312Z" transform="translate(-252.663 -180.133)" fill="#646464"  />
              <path id="Path_136" data-name="Path 136" d="M280.466,122.15a1.85,1.85,0,0,0-1.684-1.088H275v4.76h7.222Z" transform="translate(-252.663 -116.301)" fill="#646464"  />
              <path id="Path_137" data-name="Path 137" d="M19.172,67.064H1.856A1.962,1.962,0,0,0,0,69.131V80.023H21.038V69.131s0-.006,0-.01A1.969,1.969,0,0,0,19.172,67.064Z" transform="translate(0 -67.064)" fill="#646464"  />
              <path id="Path_138" data-name="Path 138" d="M309.051,277.32A2.991,2.991,0,1,0,311.8,280.3h0A2.871,2.871,0,0,0,309.051,277.32Z" transform="translate(-281.425 -258.785)" fill="#646464" />
            </g>
          </g>
        </svg>
      </template>
      <template v-slot:header-title>
        <p class="text-muted font-size-22 m-0 p-0">{{$t('userProfile.orders')}}</p>
      </template>
      <template v-slot:btn-name>
        <router-link :to="{name: 'stores'}" class="d-flex align-items-center">
          <i class="las la-plus-square text-white font-size-22"></i>
          <span class="text-white font-size-16">{{$t('userProfile.shopping')}}</span>
        </router-link>
      </template>
    </user-pages-title>
    <div v-if="loadingData" class="d-flex justify-content-center">
      <spinner-loading text="Loading"/>
    </div>
    <div v-else>
      <div v-if="orders.length">
      <iq-card
          class="py-3 px-3 position-relative"
          v-for="order in orders" :key="order.id"
          v-b-toggle="`more-content-${order.id}`"
          @click.native="toogleCards(order.id)"
      >
        <b-row v-if="order.id !== selectedId" class="align-items-center pl-5">
          <b-col lg="3" >
            <div class="d-flex flex-column">
              <p class="m-0 p-0 font-size-16 text-secondary">{{ $t('marketplace.orderNumber') }}</p>
              <p class="m-0 p-0 font-size-18 text-muted">{{ order.order_num }}</p>
            </div>
          </b-col>
          <b-col lg="4">
            <div class="d-flex flex-column">
                <span class="font-size-16 text-secondary">{{ $t('marketplace.orderDate') }}</span>
                <p class="m-0 p-0 font-size-18 text-muted">{{ order.created_at }}</p>
            </div>
          </b-col>
          <b-col lg="3">
            <div class="d-flex flex-column">
              <p class="m-0 p-0 font-size-16 text-secondary">{{ $t('marketplace.totalCostOfOrder') }}</p>
              <p class="m-0 p-0 font-size-20 text-warning">{{ order.total_amount }} {{ $t('marketplace.rs') }}</p>
            </div>
          </b-col>
          <b-col lg="1">
            <div class="arrow-collapse-open">
              <i class="las la-angle-down text-muted"></i>
            </div>
          </b-col>
          <div  :class="`status--${order.status} budge-label`">
            <p class="font-size-12">{{ transelateStatus[order.status] }}</p>
          </div>
        </b-row>
        <b-row v-else class="align-items-center mb-5">
          <b-col lg="3">
            <div class="d-flex flex-column">
              <p class="m-0 p-0 font-size-16 text-secondary">{{ $t('marketplace.orderNumber') }}</p>
              <p class="m-0 p-0 font-size-18 text-muted">{{ order.order_num }}</p>
            </div>
          </b-col>
          <b-col lg="4">
            <div class="d-flex flex-column">
              <p class="m-0 p-0 font-size-16 text-secondary">{{ $t('marketplace.storeName') }} </p>
              <p class="m-0 p-0 font-size-18 text-muted">{{order.store.name}}</p>
            </div>
          </b-col>
          <b-col lg="3" >
            <div class="d-flex flex-column">
              <span class="font-size-16 text-secondary">{{ $t('marketplace.orderDate') }}</span>
              <p class="m-0 p-0 font-size-18 text-muted">{{ order.created_at }}</p>
            </div>
          </b-col>
          <b-col lg="2">
            <div  :class="`status--${order.status} budge-label-inside`">
              <p class="p-0 m-0">{{ transelateStatus[order.status] }}</p>
            </div>
          </b-col>
        </b-row>
        <b-collapse :id="`more-content-${order.id}`" class="p-0" accordion="my-accordion" v-if="order.order_products">
          <h4 class="mb-3 text-muted font-weight-bold">{{ $t('marketplace.products') }}</h4>
          <template  v-for="(prodOrder, key) in order.order_products">
            <div :key="key">
              <b-row class="align-items-center mb-4">
                <b-col lg="2" class="product-img">
                  <div class="h-100"
                       :style="{
                    'background-image': 'url(' + prodOrder.product_image + ')',
                    'background-size' : 'cover',
                    'background-position': 'center',
                    'background-repeat': 'no-repeat'
                  }"
                  >
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="d-flex flex-column">
                    <h5 class="text-muted">{{ $t('marketplace.productName') }}</h5>
                    <p class="m-0 p-0 font-size-18">{{prodOrder.product_name}}</p>
                  </div>
                </b-col>
                <b-col lg="2">
                  <div class="d-flex flex-column">
                    <h5 class="text-muted">{{ $t('marketplace.quantity') }}</h5>
                    <p class="m-0 p-0 font-size-18">X {{ prodOrder.qty }}</p>
                  </div>
                </b-col>
                <b-col lg="3">
                  <div class="d-flex flex-column">
                    <h5 class="text-muted">{{ $t('marketplace.price') }}</h5>
                    <p class="m-0 p-0 font-size-18">{{(prodOrder.price)}} {{ $t('marketplace.rs') }}</p>
                  </div>
                </b-col>
                <b-col lg="2">
  <!--                @click.native="$router.push(`/stores/merchants/${order.store.slug}/store-profile/${order.store..url}/products/${prodOrder.id}`)"-->
                  <div @click="$router.push(`/stores/merchants/${order.store.category.slug}/store-profile/${order.store.url}/products/${prodOrder.product.id}`)">
                  <span class="m-0 p-0 text-warning font-size-18">{{ $t('marketplace.showProduct') }}</span>
                  </div>
                </b-col>
              </b-row>
            </div>
          </template>
          <div class="border-top pt-3 px-4 d-flex justify-content-between">
  <!--          <span class="text-warning cursor-pointer text-decoration-underline font-weight-bold font-size-16"> {{ $t('marketplace.printReceipt') }}</span>-->
            <b-button  class="text-warning bg-transparent border-0 cursor-pointer text-decoration-underline font-weight-bold font-size-16" @click.prevent.self="orderInvoice(order, order.id)">
              {{ $t('marketplace.printReceipt') }}
            </b-button>
            <div class="d-flex align-items-center gap_1">
              <p class="m-0 p-0 font-size-16 text-secondary">{{ $t('marketplace.totalCostOfOrder') }}</p>
              <p class="m-0 p-0 font-size-20 text-warning">{{ order.total_amount }} {{ $t('marketplace.rs') }}</p>
            </div>
          </div>
        </b-collapse>
        <span class="close-collapse" v-if="order.id === selectedId">
            <i class="las la-angle-up"></i>
          </span>
      </iq-card>
      <div class="d-flex justify-content-center">
        <b-pagination
            v-if="pagination.totalItems > pagination.itemsPerPage"
            v-model="pagination.currentPage"
            :total-rows="pagination.totalItems"
            :per-page="pagination.itemsPerPage"
            first-number
            last-number
            class="mb-3 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            align="right"
            @input="getOrders(pagination.currentPage)"
        >
        </b-pagination>
      </div>
    </div>
      <p v-else> No Available Data</p>
  </div>
  </div>
</template>

<script>
import userPagesTitle from '@/modules/userProfile/components/userPagesTitle'
import marketPlace from '@/modules/userProfile/services/userProfile'
export default {
  name: 'user-marketplace',
  components: { userPagesTitle },
  data () {
    return {
      visible: false,
      search: '',
      orders: [],
      loadingData: false,
      selectedId: '',
      pagination: {}
    }
  },
  methods: {
    orderInvoice (order, id) {
      if (order.invoice) {
        window.open(order.invoice, '_blank')
      } else {
        marketPlace.getOrderInvoice(id).then(res => {
          window.open(res.data.invoice, '_blank')
        })
      }
    },
    toogleCards (id) {
      if (this.selectedId === id) {
        this.selectedId = ''
      } else {
        this.selectedId = id
      }
    },
    getOrders (page = 1) {
      this.loadingData = true
      marketPlace.getAllOrders(page).then(res => {
        this.orders = res.data.data
        this.pagination = res.data.meta
        this.loadingData = false
      })
    }
  },
  created () {
    this.getOrders()
  }
}
</script>

<style>
.product-img{
  height: 100px !important;
}
.font-size-25{
  font-size: 25px !important;
}
.budge-label {
  white-space: nowrap;
  position: absolute;
  width: 50px !important;
  /*background-color: #EC5D61;*/
  /*color: #fff;*/
  left: 0;
  height: 100% !important;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}
.budge-label p {
  transform: rotate(90deg);
  transform-origin: center;
  margin: 0 !important;
}
.close-collapse {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -20px;
  background-color: var(--iq-primary);
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  left: calc(50% - 20px);
}
.budge-label-inside{
  /*background-color: #EC5D61;*/
  /*color: #fff;*/
  text-align: center;
  display: inline-block;
  padding: 0 20px;
  border-radius: 0 !important;
}
.arrow-collapse-open{
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.53);
  border-radius: 5px;
}
.text-decoration-underline {
  text-decoration: underline;
}
</style>
